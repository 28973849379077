<template>
  <IonApp>
    <IonSplitPane content-id="main-content" when="(min-width: 2400px)">
      <template v-if="authenticated">
        <ion-menu content-id="main-content" type="overlay">
          <ion-content color="primary">
            <ion-list id="menu-list" color="primary">
              <ion-list-header class="menu-title" color="primary">
                <router-link to="/">
                  <img style="width: 80%;" src="assets/images/brightchecker_nav.png" alt="logo">
                </router-link>
              </ion-list-header>

              <ion-menu-toggle auto-hide="false">
                <ion-item color="primary" router-direction="root" router-link="/" lines="none" detail="false" class="hydrated">
                  <div class="nav-item-container">
                    <uil-home size="20px" />
                  </div>
                  <ion-label>Home (My Jobs)</ion-label>
                </ion-item>
                <ion-item color="primary" @click="sync" lines="none" detail="false" class="hydrated">
                  <div class="nav-item-container">
                    <uil-refresh size="20px" />
                  </div>
                  <ion-label>Sync</ion-label>
                </ion-item>
                <ion-item color="primary" @click.prevent="signOut" lines="none" detail="false" class="hydrated">
                  <div class="nav-item-container">
                    <uil-signout size="20px" />
                  </div>
                  <ion-label>Logout</ion-label>
                </ion-item>
                <ion-item color="primary" @click.prevent="failSafe" lines="none" detail="false" class="hydrated">
                  <div class="nav-item-container">
                    <uil-signout size="20px" />
                  </div>
                  <ion-label>Logout and Clear Data</ion-label>
                </ion-item>
                <template v-if="email">
                  <ion-item color="primary" lines="none" detail="false" class="hydrated">
                    <div class="logged-in"><b>Logged in as:</b><br>{{ email }}</div>
                  </ion-item>
                </template>
              </ion-menu-toggle>
            </ion-list>
          </ion-content>
        </ion-menu>
      </template>

      <ion-header v-if="authenticated" class="main-header ion-no-border" no-border>
        <ion-toolbar color="primary">
          <ion-buttons slot="start">
            <ion-menu-button color="white" />
          </ion-buttons>
          <router-link to="/">
            <img class="header-width" v-bind:class="{ 'header-margin' : authenticated === true}" src="assets/images/brightchecker_nav.png" alt="logo">
          </router-link>

          <template v-if="!syncing">
            <uil-refresh  size="25px" class="header-icons" @click="sync" />
          </template>
          <template v-else>
            <ion-spinner class="header-icons" style="margin-right: 10px;" />
          </template>
          <uil-question-circle size="25px" @click="openModal" class="header-icons" style="margin-right: 10px;" />
        </ion-toolbar>
      </ion-header>

      <ion-header v-if="!authenticated" class="main-header ion-no-border" no-border>
        <ion-toolbar color="primary">
          <div class="header-container">
            <router-link to="/">
              <img class="header-width" v-bind:class="{ 'header-margin' : authenticated === true}" src="assets/images/brightchecker_nav.png" alt="logo">
            </router-link>
          </div>
        </ion-toolbar>
      </ion-header>

      <ion-router-outlet id="main-content" />
    </IonSplitPane>
  </IonApp>
</template>

<script>
import { IonButtons, IonMenuButton, IonApp, IonToolbar, IonHeader, IonContent, IonItem,
  IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonRouterOutlet, IonSplitPane,
  menuController, modalController, toastController, alertController, IonSpinner } from '@ionic/vue';
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { UilHome, UilSignout, UilQuestionCircle, UilRefresh } from '@iconscout/vue-unicons';
import HelpModal from '@/components/modals/Help';
import axios from 'axios'
import loadsData from "@/mixins/loads-data";

export default defineComponent({
  mixins: [
    loadsData,
  ],
  components: {
    IonApp, IonContent, IonItem, IonLabel, IonList, IonListHeader, IonMenu,
    IonMenuToggle, IonRouterOutlet, IonSplitPane,IonButtons,IonMenuButton,
    IonToolbar, IonHeader,
    UilHome,UilSignout,UilQuestionCircle,UilRefresh, IonSpinner
  },
  data () {
    return {
      syncing: false,
      email: ""
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
  },
  methods: {
    async openModal () {
      const helpModal = await modalController
        .create({
          component: HelpModal,
          cssClass: 'help-modal',
        })
      return helpModal.present();
    },
    ...mapActions({
      signOutAction: 'auth/signOut',
      signOutClear: 'auth/signOutAndClear',
      jobUpdate: 'brightchecker/jobUpdate'
    }),
    async signOut () {
      this.loadingTriggered();
      await this.signOutAction()
      this.closeModal();
      menuController.close();
      this.$router.go(0);
    },
    async signOutAndClear () {
      this.loadingTriggered();
      await this.signOutClear()
      this.closeModal();
      menuController.close();

      this.$router.go(0);
    },
    async openToast () {
      const toast = await toastController
        .create({
          message: 'Your settings have been saved.',
          duration: 2000
        })
      return toast.present();
    },
    async sync () {
      this.$router.push('/');
      this.syncing = true;
      this.loadingTriggered();
      this.jobUpdate()
        .catch(async (axiosException) => {
          const toast = await toastController
            .create({
              message: (axiosException && axiosException.response && axiosException.response.data && axiosException.response.data.message)
                ? `Could not sync: ${axiosException.response.data.message}`
                : 'An unknown error caused sync to fail',
              cssClass: "failed-job",
              buttons: [
                {
                  text: 'Dismiss',
                  role: 'cancel',
                },
              ]
            });

          await toast.present();
        })
        .finally(() => {
          this.syncing = false;
          this.closeModal();
        });
    },
    async failSafe () {
      const alert = await alertController
        .create({
          cssClass: 'logout-fail-safe',
          header: 'Warning!',
          message: 'Are you sure you want to logout and clear all of the apps data?',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
            },{
              text: 'Yes',
              handler: () => {
                this.signOutAndClear();
              },
            },
          ],
        });
      return alert.present();
    },
    async csrf () {
      await axios.get('/api/csrf-cookie')
    }
  },
  mounted () {
    this.csrf();
    if (this.user) {
      this.email = JSON.parse(this.user).email
    }
  },
});
</script>

<style lang="scss">
.menu-title {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  padding-bottom: 15px;
}

.nav-item-container {
  border-radius: 50%;
  background-color: #db9123;
  padding: 5px;
  height: 30px;
  width: 30px;
  color: white;
  margin-right: 10px;
}

.header-container {
  display: flex;
  justify-content: center;
  a {
    text-align: center;
  }
}

.list-md {
  padding-top: 0;
  padding-bottom: 0;
}

.main-header ion-toolbar {
  padding: 0 10px;
}

.header-icons {
  float: right;
  color: #db9123;
}

.logged-in {
  margin-top: 10px;
}
</style>
