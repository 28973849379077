import axios from 'axios'
import localforage from 'localforage';

export default {
  namespaced: true,

  state: {
    authenticated: null,
    user: null,
    token: "",
    network: {}
  },

  /* @todo: Getters that return unmodified state can be replaced with mapState() in components */
  getters: {
    authenticated (state) {
      return state.authenticated
    },
    user (state) {
      return state.user
    },
    token (state) {
      return state.token
    },
  },

  mutations: {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },

    SET_USER (state, value) {
      state.user = value
    },

    SET_TOKEN (state, value) {
      state.token = value
    },
    SET_NETWORK (state, value) {
      state.network = value;
    }
  },

  actions: {
    signIn ({ commit }, credentials) {
      return new Promise((resolvePromise, rejectPromise) => {
        return axios.get('/api/csrf-cookie').then(() => {
          axios.post('/api/login', credentials).then(resp => {
            var token = resp.data.access_token;
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            commit('SET_AUTHENTICATED', true)
            commit('SET_TOKEN', token)
            resolvePromise(resp)
          }).catch(function (resp) {
            rejectPromise(resp)
          })
        })
      })
    },

    async signOutAndClear ({ commit }) {
      commit('SET_AUTHENTICATED', false)
      commit('SET_USER', null)
      commit('SET_TOKEN', null)

      // TODO Delete all images
      localforage.keys()
        .then(function (keys) {
          for (let i in keys) {
            if (keys[i] !== "vuex") {
              localforage.removeItem(keys[i])
            }
          }
        });

      commit('brightchecker/SET_JOBS', null, { root: true })

      return await axios.post('api/logout');
    },

    async signOut ({ commit }) {
      commit('SET_AUTHENTICATED', false)
      commit('SET_TOKEN', null)
      return await axios.post('api/logout');
    },

    logout ({ commit }) {
      commit('SET_AUTHENTICATED', false)
      commit('SET_USER', null)
      commit('SET_TOKEN', null)
    }
  }
}