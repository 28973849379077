import { createRouter, createWebHistory } from '@ionic/vue-router';
import store from '@/store'
import axios from 'axios';

const routes = [
  {
    path: '/',
    component: () => import('../views/Home'),
    name: "home",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    component: () => import('../views/Settings'),
    name: "settings",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: "login",
    component: () => import('../views/Login'),
    meta: {
      hideForAuth: true
    }
  },
  {
    path: '/job/:id',
    name: "job",
    component: () => import('../views/Job'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/job/:id/section/:section',
    name: "job_section",
    component: () => import('../views/Job'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/additem/:id/section/:section',
    name: "additem",
    component: () => import('../views/AddItem'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sections/:id',
    name: "sections",
    component: () => import('../views/Sections'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/job/:id/section/:section/item/:item',
    name: "item",
    component: () => import('../views/Item'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/submit/job/:id/',
    name: "jobsubmit",
    component: () => import('../views/Submit'),
    meta: {
      requiresAuth: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return {x: 0, y: 0}
  }
})


router.beforeEach(async (to, from, next) => {
  await store.restored

  axios.defaults.headers.common.Authorization = "Bearer " + store.getters['auth/token'];

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/authenticated']) {
      next();
      return;
    } else {
      next({ path: '/login' });
      return;
    }
  }

  if (to.matched.some(record => record.meta.hideForAuth)) {
    if (store.getters['auth/authenticated']) {
      next({ name: 'home' });
      return;
    } else {
      next();
      return;
    }
  } else {
    next();
    return;
  }
})

export default router
