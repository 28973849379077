import localForage from 'localforage';

export default {
  getImage (id) {
    id = id.toString();
    return new Promise((resolvePromise, rejectPromise) => {
      localForage.getItem(id).then(function (value) {
        if (value !== undefined
                    && value !== null
        ) {
          resolvePromise(value);
        }
      }).catch(function () {
        rejectPromise(false)
      });
    })
  },
  setImage (obj, id) {
    return new Promise((resolvePromise, rejectPromise) => {
      localForage.setItem(
        id,
        obj
      ).then(function () {
        resolvePromise(true);
      }).catch(function () {
        rejectPromise()
      });
    })
  },
  removeImage (id) {
    localForage.removeItem(id.toString()).then(function () {

    })
  }
}