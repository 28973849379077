<template>
  <div class="ion-padding inner-content">
    <div class="saving-content">
      <div class="saving-text first">
        <div v-for="(line, index) in topLines" :key="index">
          {{ line }}
        </div>
      </div>
      <ion-progress-bar type="indeterminate" />
      <div class="saving-text second">
        <div v-for="(line, index) in bottomLines" :key="index">
          {{ line }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  IonProgressBar,
} from '@ionic/vue';

export default {
  components: {
    IonProgressBar,
  },
  props: {
    topLines: {
      type: Array,
      default: () => ([
        'Loading…',
      ]),
    },
    bottomLines: {
      type: Array,
      default: () => ([
        'Please wait…',
      ]),
    },
  },
};
</script>

<style scoped lang="scss">
.saving-content {
  .saving-text {
    color: #373636;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    font-family: 'Raleway', sans-serif;
    &.first {
      margin-bottom: 20px;
    }
    &.second {
      margin-top: 20px;
    }
  }
}
</style>
