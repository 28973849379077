import { createApp } from 'vue'
import axios from 'axios'
import { IonicVue } from '@ionic/vue';
import { Network } from '@capacitor/network';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import VueSignaturePad from 'vue-signature-pad';
import App from '@/App'
import store from '@/store'
import router from '@/router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import '@/theme/variables.scss';
import '@/theme/custom.scss';

import '@/registerServiceWorker'


axios.defaults.withCredentials = true;
// axios.defaults.baseURL = 'http://brightchecker.local/'
axios.defaults.baseURL = 'https://dashboard.brightchecker.com'
// axios.defaults.baseURL = 'https://staging.brightchecker.com'
// axios.defaults.baseURL = 'https://brightchecker.quickfire.digital/';

const logCurrentNetworkStatus = async () => {
  const status = await Network.getStatus();
  store.commit("auth/SET_NETWORK", status)
};
logCurrentNetworkStatus();

Network.addListener('networkStatusChange', status => {
  store.commit("auth/SET_NETWORK", status)
});

startLoggedIn();

function startLoggedIn () {
  const app = createApp(App)
    .use(store)
    .use(IonicVue)
    .use(router)
    .use(defineCustomElements)
    .use(VueSignaturePad);
  router.isReady().then(() => {
    app.config.devtools = true

    app.mount('#app');
  });
}