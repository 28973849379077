import Vuex from 'vuex'
import auth from './modules/auth'
import brightchecker from './modules/brightchecker'
// import images from './modules/images'

import localForage from 'localforage';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  storage: localForage,
  asyncStorage: true,
  supportCircular: true,
});

const store = new Vuex.Store({
  modules: {
    auth,
    brightchecker,
  },
  plugins: [
    vuexLocal.plugin
  ],
})

export default store;