import axios from 'axios'
import image_functions from '@/helpers/localforage_functions';

function sortData (data) {
  let original = Object.assign({}, data);
  return JSON.stringify(original)
}

export default {
  namespaced: true,

  state: {
    jobs: null,
  },

  getters: {
    jobs (state) {
      return JSON.parse(state.jobs)
    },
  },

  mutations: {
    SET_JOBS (state, value) {
      state.jobs = (typeof value === 'string')
        ? value
        : JSON.stringify(value);
    },
  },

  actions: {
    async me ({ commit, getters, rootState }) {
      let user = JSON.parse(rootState.auth.user);

      if (user == null) {
        return axios.get('/api/me').then((response) => {
          let userObj = response.data.user;
          let images = userObj.images;

          let jobsString = sortData(userObj.jobs);
          delete userObj.jobs
          delete userObj.images
          let userString = sortData(userObj);

          commit('auth/SET_AUTHENTICATED', true, { root: true })
          commit('SET_JOBS', jobsString)
          commit('auth/SET_USER', userString, { root: true })
          for (let key in images) {
            image_functions.setImage(images[key], key)
          }
        }).catch(() => {
          commit('auth/SET_AUTHENTICATED', false, { root: true })
          commit('auth/SET_USER', null, { root: true })
        })
      } else {
        // sync jobs instead
        commit('auth/SET_AUTHENTICATED', true, { root: true })

        let ids = [];
        for (let i = 0; i < getters.jobs.length; i++) {
          ids.push(getters.jobs[i].id);
        }
        let data = {
          ids: ids
        }
        return axios.post('/api/jobUpdate', data).then((response) => {
          let user_id = response.data.user.id;

          let userObj = response.data.user;
          let images = userObj.images;
          // let imageString = sortData(images);
          let jobsString = sortData(userObj.jobs);
          delete userObj.jobs
          let userString = sortData(userObj);

          // new user logged in
          if (user_id != user.id) {
            commit('auth/SET_AUTHENTICATED', true, { root: true })
            commit('SET_JOBS', jobsString)
            commit('auth/SET_USER', userString, { root: true })
            for (let key in images) {
              image_functions.setImage(images[key], key)
            }
          } else {
            let current_jobs = getters.jobs;
            let ids = Object.keys(getters.jobs);
            let data = {
              ids: ids
            }

            axios.post('/api/jobUpdate', data).then((response) => {
              let userObj = response.data.user;
              let jobsObj = userObj.jobs;
              if (response.data.needs_adding && response.data.needs_adding.length > 0) {
                let updates = response.data.needs_adding;
                for (const u of updates) {
                  current_jobs[u] = jobsObj[u];
                }
              }

              if (response.data.needs_removing && response.data.needs_removing.length > 0) {
                let removes = response.data.needs_removing;
                for (const r of removes) {
                  delete current_jobs[r];
                }
              }

              let jobsString = sortData(current_jobs);
              commit('SET_JOBS', jobsString)

              let new_images = userObj.images;

              for (let key in new_images) {
                image_functions.setImage(new_images[key], key)
              }
            }).catch(() => {
              return false;
            })
          }
        }).catch(() => {
          commit('auth/SET_AUTHENTICATED', false, { root: true })
          commit('auth/SET_USER', null, { root: true })
          commit('SET_JOBS', null)
        })
      }
    },

    jobUpdate ({ commit, getters }) {
      return new Promise((resolvePromise, rejectPromise) => {
        let current_jobs = getters.jobs,
          ids = Object.keys(getters.jobs),
          data = {
            ids: ids
          };

        axios.post('/api/jobUpdate', data)
          .then(
            async (response) => {
              let userObj = response.data.user,
                jobsObj = userObj.jobs;

              if (response.data.needs_adding && response.data.needs_adding.length > 0) {
                let updates = response.data.needs_adding;
                for (const u of updates) {
                  current_jobs[u] = jobsObj[u];
                }
              }

              if (response.data.needs_removing && response.data.needs_removing.length > 0) {
                let removes = response.data.needs_removing;
                for (const r of removes) {
                  delete current_jobs[r];
                }
              }

              let jobsString = sortData(current_jobs);
              commit('SET_JOBS', jobsString)

              let new_images = userObj.images;
              for (let key in new_images) {
                await image_functions.setImage(new_images[key], key)
              }
              resolvePromise();
            },
            function () {
              rejectPromise(...arguments);
            },
          )
      });
    },

    /**
     * @todo: Split into shorter actions and helper methods
     */
    sendJob ({ getters, commit, rootGetters }, options) {
      return new Promise((resolvePromise, rejectPromise) => {
        const dryRun = false,
          jobs = getters.jobs,
          requestPayload = {
            job: JSON.parse(JSON.stringify(jobs[options.id])), // Potentially-lossy clone
            images: {},
            organisation: JSON.parse(rootGetters['auth/user']).organisation
          },
          addImageToPayload = async (imageId) => {
            return new Promise((resolveAddImageToPayloadPromise) => {
              const imageIdInteger = parseInt(imageId);
              // Only send images that are currently only on device. These will be images with negative IDs. Don't add a given image multiple times.
              if (imageIdInteger && imageIdInteger < 0 && requestPayload.images[imageId] === undefined) {
                image_functions.getImage(imageId).then(imageData => {
                  requestPayload.images[imageId] = imageData;
                  resolveAddImageToPayloadPromise();
                });
              } else {
                resolveAddImageToPayloadPromise();
              }
            });
          },
          addImagesToPayload = () => {
            // Add Job's images to `requestPayload.images`. Images could be in answers or answer notes.
            // Awkwardly using `for` instead of `.forEach()` because it's slighly easier to use `await` that way
            const imagePromises = [];
            if (requestPayload.job.survey && requestPayload.job.survey.sections) {
              for (const sectionKey in requestPayload.job.survey.sections) {
                const section = requestPayload.job.survey.sections[sectionKey];
                if (section.items) {
                  for (const itemKey in section.items) {
                    const item = section.items[itemKey];
                    if (item.questions) {
                      for (const questionKey in item.questions) {
                        const question = item.questions[questionKey];
                        if (question.answer_note && question.answer_note.image) {
                          for (const imageKey in question.answer_note.image) {
                            const imageId = question.answer_note.image[imageKey]
                            imagePromises.push(addImageToPayload(imageId));
                          }
                        }

                        if (question.type && ['image', 'signature'].includes(question.type) && question.answer && question.answer.answer) {
                          imagePromises.push(addImageToPayload(question.answer.answer));
                        }
                      }
                    }
                  }
                }
              }
            }
            return imagePromises;
          };

        Promise.all(addImagesToPayload()).then(
          () => {
            // Send payload to API
            console.log("requestPayload:", requestPayload);
            if (dryRun) {
              rejectPromise();
            } else {
              axios.put('/api/job', requestPayload)
                .then(
                  async function ({data}) { // Not using arrow function because we're using magic variable `arguments`
                    jobs[requestPayload.job.id] = data.user.jobs[requestPayload.job.id];
                    /* @todo: Add `SET_JOB` mutation so we don't have to think about rebuilding `jobs` object here */
                    commit('SET_JOBS', sortData(jobs))
                    for (let key in data.user.images) {
                      await image_functions.setImage(data.user.images[key], key)
                    }
                    resolvePromise(...arguments);
                  },
                  function () { // Not using arrow function because we're using magic variable `arguments`
                    console.error("PUT /api/job failed", JSON.stringify(arguments));
                    rejectPromise(...arguments);
                  }
                )
            }
          },
          function () { // Not using arrow function because we're using magic variable `arguments`
            rejectPromise(...arguments);
          },
        );
      });
    },
  }
}