import {modalController} from "@ionic/vue";
import LoadingModal from "@/components/modals/Loading.vue";

export default {
  data: () => ({
    loadingModal: undefined,
  }),
  methods: {
    async loadingTriggered (topLines, bottomLines) {
      this.loadingModal = await modalController
        .create({
          component: LoadingModal,
          cssClass: 'auto-height',
          backdropDismiss: false,
          componentProps: {
            topLines,
            bottomLines,
          },
        })
      return this.loadingModal.present();
    },
    async closeModal () {
      if (this.loadingModal) {
        await this.loadingModal.dismiss();
      }
    },
  },
}
